import { HistogramPartiiInternal, PoparciePartii, Wynik, WynikDemo } from "./typy";

export function sortedStringify(wynik: Wynik | WynikDemo) {
  const sortedObj = {};
  Object.keys(wynik)
    .sort()
    .forEach((key) => {
      sortedObj[key] = wynik[key];
    });
  return JSON.stringify(sortedObj);
}

export function sortObjectByValues(obj: { [key: string]: number }):
  {key: string, value: number}[] {
  // Convert the object to an array of key-value pairs
  const keyValueArray = Object.entries(obj);

  // Sort the array based on the values (ascending order)
  return keyValueArray.sort((a, b) => b[1] - a[1]).map(([key, value]) => {
    return { key, value};
  });
}
