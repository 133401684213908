// Źródło:
// https://docs.google.com/spreadsheets/d/1e7Tww7BT1zWkAuYC5zWIbX9k3GhzveAWqSR8U0iDzn0/edit#gid=592625443&range=AI4:AI44,

// import { Partia, Partie, sondaz_uliczny } from "./sondaz_uliczny";

// const TD = Object.entries(sondaz_uliczny).map(([okreg, partie]) => {
//   return partie['TD'].poparcie
// });

// console.log(TD);

export const ROZKLAD: { [key: string]: number[] } = {
  LEWICA: [
    0.0282571335,
0.01488150667,
0.0478222815,
0.02984793983,
0.029476448,
0.0180548925,
0.010392221,
0.02975490417,
0.03759809833,
0.01542527167,
0.0236883605,
0.01100846683,
0.0397647275,
0.0091506275,
0.007909908333,
0.014014961,
0.008859429833,
0.010201509,
0.118083528,
0.03675424117,
0.020347473,
0.008839666333,
0.0155574055,
0.017031283,
0.032726717,
0.03074119817,
0.020098175,
0.0194996075,
0.01982511017,
0.01285778183,
0.02496772,
0.032462213,
0.0220234625,
0.0126734095,
0.01999657117,
0.02434270517,
0.021791153,
0.01856165517,
0.0379657795,
0.01779259133,
0.02895186617  ],
  // https://docs.google.com/spreadsheets/d/1e7Tww7BT1zWkAuYC5zWIbX9k3GhzveAWqSR8U0iDzn0/edit#gid=592625443&range=AN4:AN44,
  LEWICA_BLAD: [
    5.44, 4.35, 9.6, 7.9, 7.12, 3.38, 7.34, 3.42, 7.25, 3.99, 5.89, 3.0, 6.74,
    3.41, 3.53, 6.97, 6.73, 5.94, 9.39, 8.1, 1.61, 5.79, 2.92, 7.77, 5.7, 2.09,
    3.51, 8.74, 2.27, 5.02, 3.17, 5.67, 6.45, 4.54, 3.6, 6.46, 8.97, 7.86, 6.82,
    5.23, 3.79,
  ],
  // https://docs.google.com/spreadsheets/d/1e7Tww7BT1zWkAuYC5zWIbX9k3GhzveAWqSR8U0iDzn0/edit#gid=1383721529&range=U3:U43,
  KO: [
    0.02095042333,
0.01835469433,
0.04189383883,
0.02857291333,
0.02348052333,
0.02197197817,
0.01186840483,
0.02720124583,
0.0292574625,
0.01053707333,
0.0189868865,
0.01425199983,
0.03839594083,
0.009164819667,
0.008615772,
0.01198455117,
0.01145744533,
0.01229867283,
0.1181621912,
0.0343164895,
0.02074295667,
0.01193354767,
0.01593256617,
0.0210371165,
0.0441507985,
0.04206959417,
0.020244877,
0.01245059417,
0.02117911833,
0.01750180833,
0.034037158,
0.01911514317,
0.019047872,
0.01428191817,
0.017092405,
0.02281050867,
0.01443454267,
0.02173799933,
0.047433574,
0.01732140367,
0.03372117283  ],
  // https://docs.google.com/spreadsheets/d/1e7Tww7BT1zWkAuYC5zWIbX9k3GhzveAWqSR8U0iDzn0/edit#gid=1383721529&range=X3:X43,
  KO_BLAD: [
    2.38, 4.81, 2.31, 6.08, 5.05, 1.21, 1.82, 2.96, 5.81, 1.72, 4.21, 1.47,
    3.91, 6.86, 6.03, 2.58, 1.74, 1.66, 6.52, 2.43, 3.08, 2.44, 3.1, 2.78, 6.05,
    3.14, 2.42, 3.58, 3.46, 5.66, 4.79, 1.11, 1.82, 4.67, 2.93, 2.28, 1.65,
    4.96, 4.83, 5.98, 3.32,
  ],
  // TRZEBA POSORTOWAĆ ARKUSZ!!!
  // https://docs.google.com/spreadsheets/d/1e7Tww7BT1zWkAuYC5zWIbX9k3GhzveAWqSR8U0iDzn0/edit#gid=1231789773&range=R4:R44,
  TD: [
    0.022415876,
0.0140898335,
0.03175889317,
0.0283710235,
0.02891201475,
0.026946886,
0.022047627,
0.03287808558,
0.01753570292,
0.01884171267,
0.02608876458,
0.01569827233,
0.03346774583,
0.0150046695,
0.02292685725,
0.02517498925,
0.01684268933,
0.02528291583,
0.05741305392,
0.03330098842,
0.02768583583,
0.0156986055,
0.0244913335,
0.03222947392,
0.02653349625,
0.033979463,
0.02036296842,
0.01573798425,
0.017095344,
0.01606130233,
0.02022854192,
0.01415383625,
0.02527398442,
0.01563157683,
0.02427266483,
0.03357863792,
0.02057061317,
0.02859777067,
0.0285236385,
0.01738948992,
0.02690483617  ],
  // stały
  TD_BLAD: [
    7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7,
    7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7,
  ],
  // https://docs.google.com/spreadsheets/d/1e7Tww7BT1zWkAuYC5zWIbX9k3GhzveAWqSR8U0iDzn0/edit#gid=418600979&range=V3:V43,
  PIS: [
    0.02289047833,
0.014539447,
0.02776369267,
0.02131711167,
0.02294950533,
0.03838390783,
0.02973062217,
0.01900597067,
0.01591571983,
0.02408826683,
0.02904836917,
0.02130995067,
0.0311625805,
0.02963938367,
0.02562634467,
0.02475671167,
0.02382695067,
0.033949261,
0.04304594533,
0.02970509583,
0.01980732767,
0.03061604567,
0.04447523967,
0.03270840817,
0.02036073383,
0.02608811483,
0.0227350815,
0.0159613145,
0.016143496,
0.02084102017,
0.02295180183,
0.01589586733,
0.04070432767,
0.013169903,
0.01636291267,
0.02526424917,
0.02164847417,
0.01629085967,
0.01578302817,
0.012774997,
0.02076148367  ],
  // https://docs.google.com/spreadsheets/d/1e7Tww7BT1zWkAuYC5zWIbX9k3GhzveAWqSR8U0iDzn0/edit#gid=418600979&range=X3:X43,
  PIS_BLAD: [
    1.25, 2.27, 2.65, 3.22, 1.73, 6.49, 10.69, 2.54, 2.74, 8.62, 6.18, 1.37,
    7.92, 5.76, 3.48, 5.35, 7.48, 8.33, 7.79, 4.64, 3.03, 4.2, 3.39, 3.23, 3.62,
    3.23, 5.0, 2.05, 4.8, 3.58, 5.93, 3.25, 6.75, 3.18, 2.45, 3.75, 5.87, 3.43,
    6.49, 2.91, 2.37,
  ],
  // https://docs.google.com/spreadsheets/d/1e7Tww7BT1zWkAuYC5zWIbX9k3GhzveAWqSR8U0iDzn0/edit#gid=173675036&range=W3:W43,
  KONF: [
    0.02060562967,
0.01229549835,
0.03603269929,
0.02187492114,
0.022931464,
0.03703330869,
0.02519996845,
0.02217074937,
0.01722519479,
0.01994023364,
0.02252087825,
0.02050570213,
0.03539542744,
0.02552712404,
0.02291576829,
0.01795242761,
0.01920071307,
0.02779108581,
0.05962854781,
0.02940181365,
0.02027409076,
0.02730411279,
0.04747821146,
0.03286621146,
0.0258593653,
0.03152217595,
0.0235678845,
0.01403419925,
0.01896100225,
0.02215147004,
0.02308606074,
0.01587827245,
0.03247476995,
0.01196144868,
0.01944236486,
0.0257290283,
0.01804960345,
0.01795606471,
0.02234484537,
0.01189939569,
0.02101026617  ],
  // https://docs.google.com/spreadsheets/d/1e7Tww7BT1zWkAuYC5zWIbX9k3GhzveAWqSR8U0iDzn0/edit#gid=173675036&range=Z3:Z43,
  KONF_BLAD: [
    1.14, 3.14, 6.32, 4.99, 7.07, 13.57, 20.14, 3.12, 2.92, 6.45, 2.25, 10.94,
    13.54, 15.52, 13.43, 2.83, 7.4, 14.28, 11.92, 3.44, 3.64, 18.1, 12.97,
    10.15, 8.59, 3.48, 4.35, 9.07, 11.42, 5.82, 12.97, 2.62, 3.15, 8.41, 2.01,
    1.66, 6.39, 4.99, 7.92, 7.85, 7.46,
  ],
};