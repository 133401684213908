export interface Wynik {
  PIS?: number;
  KO?: number;
  TD?: number;
  LEWICA?: number;
  KONF?: number;
  BS?: number;
  PJJ?: number;
  PSL?: number;
  KO_zagrozenie?: boolean;
  TD_zagrozenie?: boolean;
  LEWICA_zagrozenie?: boolean;
  KO_szansa?: boolean;
  TD_szansa?: boolean;
  LEWICA_szansa?: boolean;
  KO_ostatnie?: boolean;
  TD_ostatnie?: boolean;
  LEWICA_ostatnie?: boolean;
  KO_dawca?: boolean;
  TD_dawca?: boolean;
  LEWICA_dawca?: boolean;
  denominator?: number;
  cenaMandatu?: number;
}

export interface WynikPolDemo {
  KO: number;
  TD: number;
  LEWICA: number;
  NIEDEMO: number;
}

export interface WynikDemo {
  DEMO: number;
  NIEDEMO: number;
}

export interface Sondaze {
  [sondaz: string]: Wynik | WynikDemo;
}

export type Okreg = {
  nr: number;
  miasto: string;
  mandaty: number;
  mandatyPKW: number;
  wynik2019: Wynik;
  glosy2019?: number;
};

export interface CzestoscMandatow {
  [mandaty: string]: number;
}

export interface PoparciePartii {
  [procent: string]: CzestoscMandatow;
}

export interface Histogram {
  [partia: string]: PoparciePartii;
}

export type Rekomendacja = {
  nrOkregu: number;
  nazwaOkregu: string;
  rekomendacjePartii: string[];
}

export interface Rekomendacje {
  [okreg: string]: Rekomendacja;
}


export const ILE_SYMULACJI = 200000;