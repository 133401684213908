import { KOLOR_PARTII, glosy2019, okregi, sondazeDoSredniej, sredniaSondazy, wybory2019 } from "./dane";
import {
  Histogram,
  ILE_SYMULACJI,
  Okreg,
  PoparciePartii,
  Sondaze,
  Wynik,
  WynikDemo,
  WynikPolDemo,
} from "./typy";

import random from "random";
import { sortObjectByValues, sortedStringify } from "./util";
import { ROZKLAD } from "./rozklad_w_okregach";
import Color from "color";

// Do jakich wartości +- od środka uwzględniać wartości, żeby wykres nie był zbyt szeroki.
const ZAKRES_DOL = 2.5
const ZAKRES_GORA = 3;

export function NaPolDemo(wynik: Wynik): WynikPolDemo {
  return {
    KO: wynik.KO,
    LEWICA: (wynik.LEWICA || 0),
    TD: (wynik.TD || 0),
    NIEDEMO: wynik.PIS + (wynik.KONF || 0),
  };
}

export function NaDemo(wynik: Wynik): WynikDemo {
  return {
    DEMO: wynik.KO + (wynik.LEWICA || 0) + (wynik.TD || 0),
    NIEDEMO: wynik.PIS + (wynik.KONF || 0),
  };
}

let glosyTD = 0;
let glosyMnoznikTD = 0;

// export function PoparcieWOkreguOLD(wynik: Wynik, okreg: Okreg): Wynik {
//   const wynikWOkregu: Wynik = {};
//   let sumaProcentowWOkregu = 0;
//   for (const [partia, procent] of Object.entries(wynik)) {
//     let mnoznik = 1;
//     if (partia === "TD") {
//       mnoznik =
//         (okreg.wynik2019.PSL! / wybory2019.PSL! +
//           okreg.wynik2019.KO! / wybory2019.KO!) /
//         2;
//       wynikWOkregu.TD = wynik.TD! * mnoznik;
//       glosyTD += (wynik.TD! * okreg.glosy2019) / 100;
//       glosyMnoznikTD += (wynik.TD! * mnoznik * okreg.glosy2019) / 100;
//     } else {
//       mnoznik =
//         wynik[partia as keyof Wynik]! / wybory2019[partia as keyof Wynik]!;
//       wynikWOkregu[partia as keyof Wynik] =
//         (okreg.wynik2019[partia as keyof Wynik] ||
//           wynik[partia as keyof Wynik]!) * mnoznik;
//     }
//     sumaProcentowWOkregu += wynikWOkregu[partia as keyof Wynik];
//   }
//   return wynikWOkregu;
// }

export function PoparcieWOkregu(wynik: Wynik, okreg: Okreg): { wynikWOkregu: Wynik, odchylenieWOkregu: Wynik} {
  const wynikWOkregu: Wynik = {};
  const odchylenieWOkregu: Wynik = {};
  for (const [partia, procent] of Object.entries(wynik)) {
    wynikWOkregu[partia] = procent * ROZKLAD[partia][okreg.nr - 1];
    odchylenieWOkregu[partia] = odchylenie(partia, procent, okreg.nr);
  }
  return { wynikWOkregu, odchylenieWOkregu };
}

// TODO @tomek można by dodać prosty test, że suma mandatów = 460?
export function PodzialMandatowKraj(wynik: Wynik) {
  glosyTD = 0;
  glosyMnoznikTD = 0;
  const mandatyWKraju = {
    PIS: 0,
    KO: 0,
    LEWICA: 0,
    TD: 0,
    KONF: 0,
  };
  for (const okreg of okregi) {
    const wynikWOkregu = PoparcieWOkregu(wynik, okreg).wynikWOkregu;
    const mandatyWOkregu = PodzialMandatowWOkregu(wynikWOkregu, okreg);
    for (const [partia, mandaty] of Object.entries(mandatyWOkregu)) {
      // TODO: @tomek czy tu może wystąpić partia inna niż {PIS, KO, LEWICA, TD, KONF}
      mandatyWKraju[partia as keyof Wynik] += mandaty;
    }
  }
  return mandatyWKraju;
}

export const dhondtKraj: { partia: string; iloraz: number; nrMandatu: number, okreg: Okreg }[] = [];

export function PodzialMandatowWOkregu(
  wynik: Wynik,
  okreg: Okreg,
  options?: { log: boolean, inputWGlosach: boolean }
): Wynik {
  const MAX_MANDATOW_DLA_KOMITETU = 12;
  const dhont: {partia: string, iloraz: number, nrMandatu: number}[] = [];
  // Jeśli ilorazy są takie same to liczy się liczba głosów, ale ignorujemy to bo interesuje nas statystyka.
  for (const [partia, procent] of Object.entries(wynik)) {
    for (let i = 1; i <= Math.min(MAX_MANDATOW_DLA_KOMITETU, okreg.mandaty); i++) {
      dhont.push({ partia, iloraz: procent / i, nrMandatu: i });
      if (options?.log) {
        dhondtKraj.push({ partia, iloraz: procent / i, nrMandatu: i, okreg });
      }
    }
  }

  const posortowanyDhont = dhont.sort((a, b) => {
    return b.iloraz - a.iloraz;
  });

  const ostatniWzietyMandat = posortowanyDhont[okreg.mandaty - 1];
  const pierwszyNiewzietyMandat = posortowanyDhont[okreg.mandaty];

  // NOTE @tomek niesamowite. 2023 i nie można napisać w TypeScript sum(glosy2019[okreg.nr - 1]) jak np. w Pythonie ;-)
  const glosyWOkregu2019 = glosy2019[okreg.nr - 1].reduce(
    (accumulator: number, currentValue: number): number => {
      return accumulator + currentValue;
    },
    0
  );

  const podzial: Wynik = posortowanyDhont
    .slice(0, okreg.mandaty)
    .map((x) => x.partia)
    .reduce((acc, cur) => {
      acc[cur] ? acc[cur]++ : (acc[cur] = 1);
      return acc;
    }, {});

  podzial.cenaMandatu = options?.inputWGlosach ? ostatniWzietyMandat.iloraz : Math.round(
    (ostatniWzietyMandat.iloraz / 100) * glosyWOkregu2019);

  // Sekcja do tabelki której aktualnie nie wyświetlamy z powodu niezdebugowanych błędów w logice poniżej (sumy powinny być prawie 100% a są dużo niższe)
  for (const d of dhont.filter((value) => value.iloraz + 0.001 >= ostatniWzietyMandat.iloraz)) {
    const glosy = d.iloraz * d.nrMandatu;
    const resztaUlamek = Math.round(10000 * (glosy % ostatniWzietyMandat.iloraz) / ostatniWzietyMandat.iloraz) / 1000;
    if (!podzial[`${d.partia}_zagrozenie`]) {
      podzial[`${d.partia}_zagrozenie`] =
        resztaUlamek < 0.20 && resztaUlamek > 0;
    }
    if (!podzial[`${d.partia}_ostatnie`]) {
      podzial[`${d.partia}_ostatnie`] = resztaUlamek === 0;
    }
    if (!podzial[`${d.partia}_dawca`]) {
      podzial[`${d.partia}_dawca`] = resztaUlamek < 0.75 && resztaUlamek > 0.25;
    }
  }
  for (const d of dhont.filter(
    (value) => value.iloraz < ostatniWzietyMandat.iloraz
  )) {
    const glosy = d.iloraz * d.nrMandatu;
    if (!podzial[`${d.partia}_szansa`]) {
      podzial[`${d.partia}_szansa`] =
        (glosy % ostatniWzietyMandat.iloraz) / ostatniWzietyMandat.iloraz >
        0.8;
    }
  }

  if (options?.log) {
    const round2Fn = (num:number) => Math.round(num * 100) / 100;
    let tabelka = `<tr><td>${okreg.miasto} (${okreg.nr})</td>`;
    // Dla wszystkich okręgów poza Warszawą dodajemy wypełniacz:
    tabelka += okreg.mandaty === 20 ? '' : `<td colspan="${20 - okreg.mandaty}">&nbsp;</td>`;
    let mandat = 0;

    // Zróbmy linijke z numerem mandatu
    // if (okreg.nr % 11 === 1) {
    //   tabelka += `<tr><td>Mandat nr</td><td colspan="${
    //     21 - okreg.mandaty
    //   }">&nbsp;</td>`;
    // }
    for (const rekord of posortowanyDhont) {
      let ramka = '';
      mandat++;
      if (mandat <= okreg.mandaty && mandat > okreg.mandatyPKW) {
        ramka = " border: 2px dashed red;";
      }
      if (mandat > okreg.mandaty && mandat <= okreg.mandatyPKW) {
        ramka = " border: 2px dashed green;";
      }
      tabelka += `<td style="background-color: ${Color(KOLOR_PARTII[rekord.partia]).fade(0.6)};${ramka}">${rekord.partia} #${rekord.nrMandatu} ${round2Fn(rekord.iloraz)}</td>`
      if (mandat === okreg.mandaty) {
        tabelka += `<td style="background-color: black">&nbsp;</td>`;
      }
      if (mandat > okreg.mandaty + 2) {
        break;
      }
    }
    tabelka += `<td>${okreg.miasto} (${okreg.nr})</td></tr>`;
    console.log(tabelka);

    // const roznica = Math.round((ostatniWzietyMandat.iloraz - pierwszyNiewzietyMandat.iloraz) * pierwszyNiewzietyMandat.nrMandatu);
    // const roznicaHTML =
    //   roznica < 1000
    //     ? `<b style="color: red">${roznica}</b>`
    //     : `<b>${roznica}</b>`;

    // console.log(
    //   `W okręgu <u>${
    //     okreg.miasto
    //   }</u> różnica głosów potrzebna żeby zmienić wynik wynosiła ${roznicaHTML}. Liczymy to jako różnicę między ostatnim wprowadzonym posłem (mandat numer #${
    //     ostatniWzietyMandat.nrMandatu
    //   }
    //   dla ${
    //     ostatniWzietyMandat.partia
    //   }) a pierwszym niebiorącym miejscem (byłby to mandat #${
    //     pierwszyNiewzietyMandat.nrMandatu
    //   } 
    //   dla ${
    //     pierwszyNiewzietyMandat.partia
    //   }). Wyliczamy to odejmując ilorazy (${round2Fn(
    //     ostatniWzietyMandat.iloraz
    //   )} -
    //   ${round2Fn(pierwszyNiewzietyMandat.iloraz)}) i mnożąc przez ${
    //     pierwszyNiewzietyMandat.nrMandatu
    //   }, bo byłby to mandat numer ${pierwszyNiewzietyMandat.nrMandatu} dla ${
    //     pierwszyNiewzietyMandat.partia
    //   } w tym okręgu.`
    // );
  }
  return podzial;
}

function czestoscNaProcentSymulacji(value: number) {
  return (Math.round(1000 * (value / ILE_SYMULACJI)) / 10).toString() + "%";
}

export function odchylenie(partia: string, procent: number, okreg: number) {
  // https://chat.openai.com/share/30b1d901-30b1-46bb-af91-a831affbc3e7
  const PROBA_LOSOWA_SONDAZY = 1000;
  const n = PROBA_LOSOWA_SONDAZY * Object.entries(sondazeDoSredniej).length;
  const p = procent / 100;
  const bladProporcji = Math.sqrt((p * (1 - p)) / n);

  // TODO @tomek funkcja odchylenie jest używana intensywnie w obliczeniach, ten sqrt(5) nie jest dla mmie oczywisty
  // Mnożymy przez sqrt(5), żeby przeliczyć bląd statystyczny z wykresu na odchylenie standardowe. Potem przeliczamy na punkty procentowe.
  // TODO @kuba zamienić na stabilne źródło danych (arkusz: https://docs.google.com/spreadsheets/d/1e7Tww7BT1zWkAuYC5zWIbX9k3GhzveAWqSR8U0iDzn0/edit#gid=1787689356) i przeliczyć ponownie bez sztuczek
  const bladOdchyleniaWRegionie =
    ROZKLAD[`${partia}_BLAD`][okreg - 1] * Math.sqrt(5) * (procent / 100);
  // return (100 * bladProporcji) + (ROZKLAD[`${partia}_BLAD`][okreg - 1] * Math.sqrt(5));

  // sumowanie odchylen standardowych: https://chat.openai.com/share/81cdfe04-09b5-45b6-8624-b3d402b12a60
  // TODO @tomek dlaczego 100 * bladProporcji? bo bladOdchyleniaWREgionie byl przeliczony na punkty procentowe a bladProporcji nie?
  return Math.sqrt((100 * bladProporcji) ** 2 + bladOdchyleniaWRegionie ** 2);
}

export function NormalizujWynik(wynik: Wynik):Wynik {
  const suma = wynik.KO + wynik.PIS + wynik.TD + wynik.KONF + wynik.LEWICA;
  const mnożnik = 100/suma;
  return {
    PIS: wynik.PIS * mnożnik,
    KO: wynik.KO * mnożnik,
    TD: wynik.TD * mnożnik,
    KONF: wynik.KONF * mnożnik,
    LEWICA: wynik.LEWICA * mnożnik,
  }
}

export function RozkladPrawdopodobienstwaWOkregu(
  wynik: Wynik,
  okreg: Okreg
): {
  procentyWOkreguSrednia: Wynik;
  odchylenieWOkregu: Wynik,
  wykresRozkladu: Sondaze;
  wykresRozkladuDemo: Sondaze;
  histogram: Histogram;
  minimumMandatow: Wynik;
  ostatnieMandaty: Wynik; // reszta 0
  zagrozoneMandaty: Wynik; // reszta (0, 25%)
  potencjalneMandaty: Wynik; // reszta (75%, 99.99%)
  dawcyGlosow: Wynik; // reszta (30%, 70%)
} {
  const { wynikWOkregu, odchylenieWOkregu } = PoparcieWOkregu(wynik, okreg);
  const procentyWOkreguSrednia = NormalizujWynik(wynikWOkregu);
  const rozklad = {};
  const rozkladDemo = {};
  const histogram: Histogram = {};
  const minimumMandatow: Wynik = {
    KO: 100,
    TD: 100,
    LEWICA: 100,
  };
  const zagrozoneMandaty: Wynik = {
    KO: 0,
    TD: 0,
    LEWICA: 0,
  };
  const potencjalneMandaty: Wynik = {
    KO: 0,
    TD: 0,
    LEWICA: 0,
  };
  const ostatnieMandaty: Wynik = {
    KO: 0,
    TD: 0,
    LEWICA: 0,
  };
  const dawcyGlosow: Wynik = {
    KO: 0,
    TD: 0,
    LEWICA: 0,
  };

  
  const bucketFn = (num, bucket) => Math.round(num * bucket) / bucket;

  for (let i = 1; i <= ILE_SYMULACJI; i++) {
    // Losowanie poparcia dla partii
    let { procentWOkreguLosoweUncapped, procentWOkreguLosoweCapped } = losowanieWyniku(procentyWOkreguSrednia, odchylenieWOkregu, bucketFn);
    // Podział mandatów
    const mandatyWOkregu = PodzialMandatowWOkregu(
      procentWOkreguLosoweUncapped,
      okreg
    );
    // info dla wykresów
    minimumMandatow.KO > (mandatyWOkregu.KO || 0)
      ? (minimumMandatow.KO = mandatyWOkregu.KO || 0)
      : null;
    minimumMandatow.LEWICA > (mandatyWOkregu.LEWICA || 0)
      ? (minimumMandatow.LEWICA = mandatyWOkregu.LEWICA || 0)
      : null;
    minimumMandatow.TD > (mandatyWOkregu.TD || 0)
      ? (minimumMandatow.TD = mandatyWOkregu.TD || 0)
      : null;

    const str = sortedStringify(NaPolDemo(mandatyWOkregu));
    const strDemo = sortedStringify(NaDemo(mandatyWOkregu));
    rozklad[str] ? rozklad[str]++ : (rozklad[str] = 1);
    rozkladDemo[strDemo] ? rozkladDemo[strDemo]++ : (rozkladDemo[strDemo] = 1);
    // Zapisanie mandatów w histogramie
    for (const [partia, procent] of Object.entries(procentWOkreguLosoweCapped)) {
      if (!histogram[partia]) {
        histogram[partia] = {};
      }
      if (!histogram[partia][String(procent)]) {
        histogram[partia][String(procent)] = {};
      }
      // Dodajemy czestosc dla danej ilości mandatów dla danego procentu ze wszystkich symulacji.
      const mandaty = String(mandatyWOkregu[partia] || 0);
      histogram[partia][String(procent)][mandaty]
        ? (histogram[partia][String(procent)][mandaty] += 1)
        : (histogram[partia][String(procent)][mandaty] = 1);
    }
    // Liczenie sytuacji zagrożenia/szansy
    if (mandatyWOkregu.KO_zagrozenie) {
      zagrozoneMandaty.KO++;
    }
    if (mandatyWOkregu.TD_zagrozenie) {
      zagrozoneMandaty.TD++;
    }
    if (mandatyWOkregu.LEWICA_zagrozenie) {
      zagrozoneMandaty.LEWICA++;
    }

    if (mandatyWOkregu.KO_szansa) {
      potencjalneMandaty.KO++;
    }
    if (mandatyWOkregu.TD_szansa) {
      potencjalneMandaty.TD++;
    }
    if (mandatyWOkregu.LEWICA_szansa) {
      potencjalneMandaty.LEWICA++;
    }

    if (mandatyWOkregu.KO_dawca) {
      dawcyGlosow.KO++;
    }
    if (mandatyWOkregu.TD_dawca) {
      dawcyGlosow.TD++;
    }
    if (mandatyWOkregu.LEWICA_dawca) {
      dawcyGlosow.LEWICA++;
    }

    if (mandatyWOkregu.KO_ostatnie) {
      ostatnieMandaty.KO++;
    }
    if (mandatyWOkregu.TD_ostatnie) {
      ostatnieMandaty.TD++;
    }
    if (mandatyWOkregu.LEWICA_ostatnie) {
      ostatnieMandaty.LEWICA++;
    }
  } // end of ILE_SYMULACJI loop

  const wykresRozkladu: Sondaze = {};
  sortObjectByValues(rozklad)
    .slice(0, 12)
    .forEach(({ key, value }) => {
      wykresRozkladu[czestoscNaProcentSymulacji(value)] = JSON.parse(
        key
      ) as Wynik;
    });

  const wykresRozkladuDemo: Sondaze = {};
  sortObjectByValues(rozkladDemo)
    .slice(0, 3)
    .forEach(({ key, value }) => {
      wykresRozkladuDemo[czestoscNaProcentSymulacji(value)] = JSON.parse(
        key
      ) as Wynik;
    });

  return {
    procentyWOkreguSrednia,
    odchylenieWOkregu,
    wykresRozkladu,
    wykresRozkladuDemo,
    histogram,
    // W Warszawie mamy b. szeroki zakres możliwości, szczególnie dla KO i Lewicy, podbijamy więc tam minimalne mandaty, żeby wykres wyglądał lepiej
    minimumMandatow: {
      ...minimumMandatow,
      KO: okreg.nr === 19 ? 8 : minimumMandatow.KO,
      LEWICA: okreg.nr === 19 ? 2 : minimumMandatow.LEWICA,
    },
    zagrozoneMandaty,
    potencjalneMandaty,
    ostatnieMandaty,
    dawcyGlosow,
  };
}

export function losowanieWyniku(procentyWOkreguSrednia: Wynik, odchylenieWOkregu: Wynik, bucketFn: (num: any, bucket: any) => number) {
  let procentWOkreguLosoweUncapped: Wynik = {};
  for (const [partia, procent] of Object.entries(procentyWOkreguSrednia)) {
    const wynikLosowyUncapped = Math.max(random.normal(
      procent,
      odchylenieWOkregu[partia]
    )(), 0);
    procentWOkreguLosoweUncapped[partia] = wynikLosowyUncapped;
  }
  procentWOkreguLosoweUncapped = NormalizujWynik(procentWOkreguLosoweUncapped);
  let procentWOkreguLosoweCapped: Wynik = {};
  for (const [partia, procent] of Object.entries(
    procentWOkreguLosoweUncapped
  )) {
    // Musimy używać tego samego odchylenia w każdej symulacji do cappowania
    const procentOczekiwany = procentyWOkreguSrednia[partia];
    const stddev = odchylenieWOkregu[partia];
    const wynikLosowyCapped = bucketFn(
      Math.max(
        Math.min(procent, procentOczekiwany + stddev * ZAKRES_GORA),
        Math.max(procentOczekiwany - stddev * ZAKRES_DOL, 0)
      ),
      5 // dzielimy kazdy procent na przedziały co 0.2%, czyli na 5 bucketów
    );
    procentWOkreguLosoweCapped[partia] = wynikLosowyCapped;
  }
  return { procentWOkreguLosoweUncapped, procentWOkreguLosoweCapped };
}
