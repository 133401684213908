export type Komentarz = {
  nazwaOkregu?: string,
  nrOkregu?: number,
  txt?: string;
  rekomendacjePartii?: string[];
};
export interface Komentarze {
  [key: string]: Komentarz,
}

// komitet - jeśli są dwa komitety w nazwie, to muszą być posortowane alfabetycznie
export const komentarze: Komentarze = {
  Legnica: {
    nazwaOkregu: "Legnica",
    nrOkregu: 1,
    rekomendacjePartii: ["KO", "LEWICA"],
    txt: "Lewica i KO konkuruje o ostatni mandat z PiS i Konfederacją, warto wesprzeć swym głosem jedną z nich.",
  },
  Wałbrzych: {
    nazwaOkregu: "Wałbrzych",
    nrOkregu: 2,
    rekomendacjePartii: ["LEWICA", "TD"],
    txt: "Lewica i Trzecia Droga konkuruje o ostatni mandat z PiS, warto wesprzeć swym głosem jedną z nich.",
  },
  Wrocław: {
    nazwaOkregu: "Wrocław",
    nrOkregu: 3,
    rekomendacjePartii: ["KO", "TD"],
    txt: "KO ma największe szanse zdobyć 6 mandatów. Trzecia Droga ma szanse na drugi mandat. Warto wesprzeć swym głosem jedną z nich, bo mają największe szanse zdobyć mandat kosztem PiS lub Konfederacji.",
  },
  Bydgoszcz: {
    nazwaOkregu: "Bydgoszcz",
    nrOkregu: 4,
    rekomendacjePartii: ["LEWICA", "TD"],
    txt: "Trzy partie opozycyjne razem konkurują o mandat z Konfederacją. Należy wesprzeć Lewicę i Trzecią Drogę, bo mają najbardziej przewidywalne, wysokie szanse na kolejne mandaty.",
  },
  Toruń: {
    nazwaOkregu: "Toruń",
    nrOkregu: 5,
    rekomendacjePartii: ["LEWICA", "TD"],
    txt: "Trzy partie opozycyjne razem konkurują o mandat z Konfederacją. Należy wesprzeć Lewicę i Trzecią Drogę, bo ma najwyższą szansę na kolejny mandat.",
  },
  Lublin: {
    nazwaOkregu: "Lublin",
    nrOkregu: 6,
    rekomendacjePartii: ["KO", "TD"],
    txt: "Wszystkie 5 ugrupowań walczy na równych szansach o 3 ostatnie mandaty. Warto wesprzeć KO i Trzecią Drogę, gdyż mają największe szanse w walce z PiS i Konfederacją.",
  },
  Chełm: {
    nazwaOkregu: "Chełm",
    nrOkregu: 7,
    rekomendacjePartii: ["LEWICA", "TD"],
    txt: "Lewica walczy z PiS o ostatni mandat i potrzebuje w tym okręgu wsparcia.",
  },
  "Zielona Góra": {
    nazwaOkregu: "Zielona Góra",
    nrOkregu: 8,
    rekomendacjePartii: ["LEWICA"],
    txt: "O ostatni mandat walczy PiS, Konfederacja i Trzecia Droga. Na pewno trzeba wesprzeć Trzecią Drogę, ale poparcie KO również jest uzasadnione.",
  },
  Łódź: {
    nazwaOkregu: "Łódź",
    nrOkregu: 9,
    rekomendacjePartii: ["KO", "TD"],
    txt: "O ostatni mandat walczy PiS, Konfederacja i Trzecia Droga. Na pewno trzeba wesprzeć Trzecią Drogę, ale poparcie KO również jest uzasadnione.",
  },
  "Piotrków Trybunalski": {
    nazwaOkregu: "Piotrków Trybunalski",
    nrOkregu: 10,
    rekomendacjePartii: ["KO", "LEWICA"],
    txt: "Cztery partie walczą o ostatnie trzy przydzielane mandaty: KO, Lewica, PiS i Konfederacja. By nie stracić jednego z mandatów demokratycznych potrzebne jest wsparcie dla KO i Lewicy.",
  },
  Sieradz: {
    nazwaOkregu: "Sieradz",
    nrOkregu: 11,
    rekomendacjePartii: ["TD"],
    txt: "PiS i Trzecia Droga walczą o ostatni mandat, dodatkowe wsparcie Trzeciej Drogi z okręgów Kalisz i Konin pomoże odebrać PiS mandat.",
  },
  Chrzanów: {
    nazwaOkregu: "Chrzanów",
    nrOkregu: 12,
    rekomendacjePartii: ["TD"],
    txt: "PiS, Konfederacja i Trzecia Droga walczą o ostatnie dwa mandaty, dodatkowe wsparcie Trzeciej Drogi daje największe szanse na odebranie PiS mandatu.",
  },
  Kraków: {
    nazwaOkregu: "Kraków",
    nrOkregu: 13,
    rekomendacjePartii: ["LEWICA"],
    txt: "Trzy ugrupowania walczą z PiS o ostatnie 3 mandaty. Jedna z partii może stracić ten mandat na rzecz PiS, dlatego każda partia wymaga poparcia. Sugerujemy Lewicę, gdyż KO i Trzecia droga mogą strategicznie wyjechać odpowiednio do Tarnowa i Chrzanowa.",
  },
  "Nowy Sącz": {
    nazwaOkregu: "Nowy Sącz",
    nrOkregu: 14,
    rekomendacjePartii: ["LEWICA"],
    txt: "W tym okręgu rekomendujemy wsparcie Lewicy, za pomocą wyborców niezdecydowanych z tego okręgu oraz turystyki wyborczej Lewicy z okręgów Tarnów i Chrzanów. Więcej o tej strategi w artykule podlinkowanym pod logo Lewicy.",
  },
  Tarnów: {
    nazwaOkregu: "Tarnów",
    nrOkregu: 15,
    rekomendacjePartii: ["TD"],
    txt: "Domowy okręg Władysława Kosiniaka-Kamysza, Trzecia Droga z realnymi szansami na drugi mandat. Wyborcy Lewicy powinni głosować w okręgu Nowy Sącz, gdzie Lewica wsparta wyborcami z Tarnowa i Chrzanowa walczy o pierwszy mandat.",
  },
  Płock: {
    nazwaOkregu: "Płock",
    nrOkregu: 16,
    rekomendacjePartii: ["LEWICA", "TD"],
    txt: "Lewica i Trzecia Droga mają szanse odebrać mandat Konfederacji. Obydwie te partie powinny liczyć na wsparcie ich wyborców z okręgów 20 w przypadku Trzeciej Drogi, oraz 17 w przypadku Lewicy. Warto wesprzeć swym głosem jedną z nich.",
  },
  Radom: {
    nazwaOkregu: "Radom",
    nrOkregu: 17,
    rekomendacjePartii: ["KO"],
    txt: "Wyborcy Lewicy powinni wesprzeć turystyką wyborczą Piotrków Trybunalski i częściowo okręg płocki. Jest to okręg, gdzie spodziewanych jest wielu wyborców z Warszawy. Dla wzmocnienia tego efektu rekomendowane jest wsparcie KO.",
  },
  Siedlce: {
    nazwaOkregu: "Siedlce",
    nrOkregu: 18,
    rekomendacjePartii: ["KO", "TD"],
    txt: "To jest okręg, w którym spodziewany jest największy udział wyborców, którzy przyjadą tu z Warszawy, będą to głównie wyborcy KO. Trzecia Droga zdobędzie prawdopodobnie dwa mandaty, Lewica ma zbyt niskie poparcie na pierwszy mandat. <br/>Rekomendacja wsparcia KO, dla wzmocnienia efektu turystyki wyborczej.<br/> Potrzebne jest wsparcie Trzeciej Drogi by nie doszło do odebrania mandatu nie PiS a Trzeciej Drodze przez KO",
  },
  "Warszawa I": {
    nazwaOkregu: "Warszawa I",
    nrOkregu: 19,
    rekomendacjePartii: ["LEWICA", "TD"],
    txt: "Warszawa I: Wyborcy KO powinni masowo zagłosować w okręgach Siedlce i Radom. Jest to strategia oparta na głosowaniu tam, gdzie jest większa siła głosu. Przy masowym wyjeździe z Warszawy do okręgu siedleckiego i radomskiego KO może stracić mandat, ale z dużym prawdopodobieństwem zostanie on przejęty przez Lewicę albo Trzecią Drogę. <br/>Część wyborców KO (mieszkańcy Bielan) powinni zagłosować w okręgu podwarszawskim dla zapewnienia piątego mandatu dla KO w tym okręgu. <br/>Wyborco Lewicy, Trzeciej Drogi, niezdecydowani lub gotowi działać strategicznie - Lewica w okręgu 19 (Warszawa) walczy o 4. mandat a Trzecia o 2. Więc jeśli chcesz to zagwarantować zagłosuj na jedną z tych partii lub wyjedź.",
  },
  "Warszawa II": {
    nazwaOkregu: "Warszawa II",
    nrOkregu: 20,
    rekomendacjePartii: ["KO"],
    txt: "W tym okręgu po jednym mandacie zdobędą Lewica, Trzecia Droga i Konfederacja. Pozostałe 9 podzielą KO i PiS i może zadecydować różnica jednego głosu. Wyborcy KO powinni głosować w swoim domowym okręgu. Wyborcy Lewicy mają bezpieczne poparcie zapewniające pierwszy mandat. Wyborcy Lewicy mieszkający blisko okręgu płockiego, powinny wesprzeć Lewicę w okręgu płockim. Wyborcy Trzeciej Drogi, którzy mogą głosować w innym okręgu, powinni wybrać okręg płocki lub siedlecki.",
  },
  Opole: {
    nazwaOkregu: "Opole",
    nrOkregu: 21,
    rekomendacjePartii: [],
    txt: "Założyliśmy, że w Opolu mandat weźmie (tak jak w kilku ostatnich wyborach) Mniejszość Niemiecka, a uwzględnienie jej w modelu zaciemniłoby obraz całości. Policzenie, że w Opolu jest 11 mandatów (a nie 12) było tutaj najłatwiejszym rozwiązaniem.",
  },
  Krosno: {
    nazwaOkregu: "Krosno",
    nrOkregu: 22,
    rekomendacjePartii: ["KO"],
    txt: "Największe szanse na kolejny mandat ma KO. Lewica bez realnych szans na mandat. Jej wyborcy powinni wesprzeć okręg rzeszowski.",
  },
  Rzeszów: {
    nazwaOkregu: "Rzeszów",
    nrOkregu: 23,
    rekomendacjePartii: ["LEWICA"],
    txt: "Lewica walczy o mandat przy wsparciu turystyki wyborczej z Krosna, Pozostałe partie ze stabilnym poparciem.",
  },
  Białystok: {
    nazwaOkregu: "Białystok",
    nrOkregu: 24,
    rekomendacjePartii: ["KO", "LEWICA"],
    txt: "Lewica walczy o ostatnie dwa mandaty z PiS i Konfederacją. Aby ochronić mandat należy wspierać Lewicę. Próba przejęcia dodatkowego mandatu jest związana z głosem na KO, której najbliżej do kolejnego mandatu.",
  },
  Gdańsk: {
    nazwaOkregu: "Gdańsk",
    nrOkregu: 25,
    rekomendacjePartii: ["KO"],
    txt: "KO i Konfederacja walczą o ostatni mandat w okręgu, dlatego należy wesprzeć KO.",
  },
  Gdynia: {
    nazwaOkregu: "Gdynia",
    nrOkregu: 26,
    rekomendacjePartii: ["KO"],
    txt: "Trzecia Droga i Lewica ze stabilnym poparciem na jeden mandat. Najwieksze szanse walki o kolejny mandat ma KO.",
  },
  "Bielsko-Biała": {
    nazwaOkregu: "Bielsko-Biała",
    nrOkregu: 27,
    rekomendacjePartii: ["KO"],
    txt: "KO walczy Konfederacja i PiS o ostatni mandat w okręgu, dlatego należy wesprzeć KO.",
  },
  Częstochowa: {
    nazwaOkregu: "Częstochowa",
    nrOkregu: 28,
    rekomendacjePartii: ["KO"],
    txt: "PiS i KO walczą o ostatni mandat, wobec tego należy tutaj wesprzeć KO.",
  },
  Gliwice: {
    nazwaOkregu: "Gliwice",
    nrOkregu: 29,
    rekomendacjePartii: ["KO"],
    txt: "KO może być jedynym ugrupowaniem, które może odebrać Konfederacji pierwszy mandat. Twój głos wspierający to upgrupowanie tą szanse zwieksza.",
  },
  Rybnik: {
    nazwaOkregu: "Rybnik",
    nrOkregu: 30,
    rekomendacjePartii: ["KO"],
    txt: "Trzy ugrupowania demokratyczne walczą z Konfederacją o ostatnie 3 mandaty. KO i Lewica mają najmniej pewną sytuację, warto wesprzeć swym głosem jedną z nich.",
  },
  Katowice: {
    nazwaOkregu: "Katowice",
    nrOkregu: 31,
    rekomendacjePartii: ["KO"],
    txt: "KO walczy z PiS o ostatni mandat w okręgu, należy wesprzeć KO w tych wysiłkach.",
  },
  Sosnowiec: {
    nazwaOkregu: "Sosnowiec",
    nrOkregu: 32,
    rekomendacjePartii: ["TD"],
    txt: "Trzecia Droga walczy z Konfederacja o ostatni mandat. Należy ją wesprzeć w tych wysiłkach.",
  },
  Kielce: {
    nazwaOkregu: "Kielce",
    nrOkregu: 33,
    rekomendacjePartii: ["KO", "TD"],
    txt: "Kielce jako okręg o dużej sile głosu będzie zapewne celem turystyki wyborczej z innych okręgów. Jest to miejsce, gdzie Giertych na liście KO konkuruje z Kaczyńskim na liście PiS. Warto wesprzeć KO, które może liczyć na dodatkowych wyborców związanych z turystyką wyborczą. Trzecia Droga może potrzebować wsparcia by pewnie zdobyć 2 mandat.",
  },
  Elbląg: {
    nazwaOkregu: "Elbląg",
    nrOkregu: 34,
    rekomendacjePartii: ["KO", "LEWICA"],
    txt: "KO ze stabilnym poparciem na 3 mandaty. Elbląg jako okręg o największej sile głosu będzie zapewne celem turystyki wyborczej motywowanej siłą głosu. Tacy wyborcy powinni wesprzeć KO lub Lewicę.",
  },
  Olsztyn: {
    nazwaOkregu: "Olsztyn",
    nrOkregu: 35,
    rekomendacjePartii: ["KO", "TD"],
    txt: "Walka o ostatni mandat między Trzecią Drogą, KO i PiS. Twój głos na Trzecią Drogę lub KO zwiększa ich szanse na mandat.",
  },
  Kalisz: {
    nazwaOkregu: "Kalisz",
    nrOkregu: 36,
    rekomendacjePartii: ["KO"],
    txt: "Wyborcy Lewicy mają pewny jeden mandat, wyborcy Trzeciej Drogi moga wspierać turystyką wyborczą okręg 11. Najbliżej zdobycia mandatu jest KO, które należy wspierać.",
  },
  Konin: {
    nazwaOkregu: "Konin",
    nrOkregu: 37,
    rekomendacjePartii: ["KO"],
    txt: "Wyborcy Lewicy mają pewny jeden mandat, wyborcy Trzeciej Drogi moga wspierać turystyką wyborczą okręg 11. Najbliżej zdobycia mandatu jest KO, które należy wspierać.",
  },
  Piła: {
    nazwaOkregu: "Piła",
    nrOkregu: 38,
    rekomendacjePartii: ["TD"],
    txt: "Trzecia droga ma największe szanse na kolejny mandat, również przy wsparciu turystyką wyborczą z Poznania i Koszalina.",
  },
  Poznań: {
    nazwaOkregu: "Poznań",
    nrOkregu: 39,
    rekomendacjePartii: ["KO", "LEWICA"],
    txt: "KO i Lewica walczą o ostani mandat z Konfederacją, warto wesprzeć swym głosem jedną z nich.",
  },
  Koszalin: {
    nazwaOkregu: "Koszalin",
    nrOkregu: 40,
    rekomendacjePartii: ["KO"],
    txt: "PIS z dużym prawdopodobieństwem zdobędzie ostatni mandat. Ponieważ żadna z mniejszych partii demokratycznych nie ma wystarczającej liczby głosów by była szansa na drugi mandat należy poprzeć KO. Wyborcy Trzeciej Drogi mogą wspierać turystyką wyborczą walkę o dodatkowy mandat w Pile.",
  },
  Szczecin: {
    nazwaOkregu: "Szczecin",
    nrOkregu: 41,
    rekomendacjePartii: ["KO"],
    txt: "PiS i Konfederacja mają największe szanse na ostatnie mandaty. Wspierając Trzecią Drogę lub Lewicę zwiększasz szanse na odebranie im tych mandatów.",
  },
};

export const komentarzKoncowy = [
"KO zdobyło +2 mandaty kosztem Lewicy i Konfederacji. 11k zaświadczeń, x2 do 2019. KO wyprzedziło Konfederację o 2k mandatów. Turystyka mogła mieć tu wpływ.",
"Nie przydatna rekomendacja, bo nie dała Lewicy mandatu (zabrakło 5k). Trzecia Droga miała mandat i tak zapewniony z zapasem 9k głosów. Na szczęście stracony mandat Lewicy był na rzecz KO.",
"We Wrocławiu ostatni mandat dostała KO ale 7k przed Lewicą. Trudno powiedziec kim było 14k osób z zaświadczeniem, było to 1.6x stanu z 2019, zapewne Studenci i osoby spoza Wrocławia pracujące we Wrocławiu. ",
"KO odebrała ostani mandat Konfederacji przewagą 3k głosów. To nie była nasza rekomendacja. Kto dał te 3k głosów? Może turyści, bo było 8.6k zaświadczeń i było to 2.5x to co w 2019.",
"Tu nasze przewidywania się nie sprawdziły - Lewica nie dostała -1 a PiS +1 mandatu, jednak żadne działania na poziomie kilku tysięcy by nic nie zmieniły. Ostatni mandat dostała Konfederacja z dużą przewagą nad wszystkimi 4 pozostałymi partiami.",
"W stosunku do przewidywań Trzecia odbiła 1 mandat Konfederacji. Jednak zrobiła to z bezpiecznym zapasem 30k głosów, przy 6k dodatkowych głosach mogłaby zdobyć 3 mandat i zabrać go PIS, który dostał ostani Mandat.  Zaświadczeń było 9.4k i było to prawie x2 do 2019.",
"Tu wynik jest zgodny z przewidywaniami, choć zgodnie z rekomendacją Trzeciej (ostatni mandat) i Lewicy (zabrakło 4k) potrzeba by było dodatkowych głosów aby odebrać ostatni mandat PiS.",
"Błędna rekomendacja, wbrew rekomendacji KO odbiło mandat Konfederacji. KO przegoniła PiS o 12 tyś głosów. Było tutaj dużo turystyki - 10k zaświadczeń 3.3x tego co w 2019.",
"Tu żadna rekomendacja nie była potrzebna - ostatni mandat dostała KO z dużą przewagą nad pozostałymi 4 partiami. Liczyliśmy na +1 mandat dla Lewicy i -1 mandat dla KO. Wynik nie zmienił notowań demokratów. NIe przewidzieliśmy tak dużego wyniku Trzeciej.",
"Tu rekomendacja głosowania na Lewicę nie wystarczyła, zabrakło 5k głosów by odebrać PiS ostatni mandat. W kolejce po ostatni głos zaraz za PiS była Konfederacja - zabrakło im 600 głosów, Natomiast mandat KO był bezpieczny.",
"Trzecia dostała 1 mandat więcej niż oczekiwaliśmy kosztem Konfederacji. Przegoniła ona PIS o 3.5k a Konfederację o 4k. Osób głosujących z zaświadczeniem było 7k i to było 3.1x tego co w 2019.",
"Nasza rekomendacja zakładała niższe poparcie TD. Do kolejnego mandatu brakowało im 8k głosów natomiast KO tylko 4k, i bylo to 2.3 x stan z 2019. Konfederacji zabrakło 3k głosów do odebrania mandatu ale PiS, a nie partiom demokratycznym.",
"Mimo rekomendacji Lewicy, zabrakło jej 9k do 2. mandatu. Na szczescie mandat ten wzieła TD, ale nie wymagał on wsparcia bo to był mandat przedostani.",
"Tu nasza rekomendacja była naiwna. Mieliśmy nadzieję, że wyborcy Lewicy z sąsiadujacych okręgów mogą tu przyjechać i się zjednoczyć, ale tak się nie stało.",
"KO i Trzecia odebrały po jednym mandacie PiS i Konfederacji. Rekomendacja prawie dobra, bo KO nie dostała rekomendacji a nie wiele brakowało (2k głosów) aby odebrać mandat Konfederacji. Mandat TD był wzięty z górką 6.5k głosów, więc pewnie nie wymagało to głosowania strategicznego na TD.",
"KO zdobyło ostatni mandat przewagą 4k przed PiS i 5k przed Konfederacją i Lewicą. TD zdobyła +1 w stosunku do przewidywań. Można zakładać duży wpływ Turystyki bo było 7.5k głosujących z zaświadczeniem - x4 w stosunku do 2019.",
"Tu nasza rekomendacja nic nie dała KO - realnie to PiS i Konfederacja walczyły o ostatni mandat. Wygrała PiS przewagą 2.5k mandatów.  ",
"Tu mandaty rozdano zgodnie z przewidywaniami nie uwzgledniającymi turystyki. Czyli Turystyka nie miała tu znaczenia mimo że było to 10k głosujących - 4.1x wynik z 2019. Zarówno KO jak i Trzeciej Drodze zabrakło po 12k do mandatu i odebrania go PiS. Rekomendacja trafna ale poparcie nie było wystarczające.",
"Tu rekomendacja była prawidłowa - za wsparciem Trzeciej Drogi i Lewicy. Lewica wzięła przewidywane 3 mandaty a Trzecia dostała o 1 więcej niż przewidywaliśmy. Liczba zaświadczeń to 63k czyli 1.6x liczby z 2023, ale połowa tego za granicą. KO straciło mandat przez turystykę bo zabrakło im 15k do 10 mandatu. ale zyskaliśmy dzięki temu mandaty w Płocku i Kielcach oraz było bardzo blisko dodatkowego mandatu w Obwarzanku (Okręg 20). Jednak szansa na stratę mandatu w okręgu 19 przez KO na rzecz partii populistycznych wynosiła tylko 25% i mieliśmy szczęście bo to się nie zdarzyło - KO oddało swój mandat TD a zyskała tam, gdzie pojechali turyści (Płock i Kielce).",
"Tu rekomendacje były dobre ale niewystarczające - gdyby KO zdobyło 311 głosów więcej a Lewica o 20 więcej to KO zdobyło by jeszcze jeden mandat od PiS. Pewnie wystarczyło by 500 turystów więcej.  Zaświadczeń było 20k czyli 3.8x tego co w 2019.",
"Tu nie mieliśmy rekomendacji, baliśmy się zaszkodzić tracącej z wyborów na wybory MN. Wygląda na to że już nie odzyskają swojego mandatu. W stosunku do przewidywań KO dostało +2 a TD -1 i MN-1.",
"Otrzymane mandaty zgodnie z prognozą, wsparcie KO nie zaszkodziło, ale też nie pomogło realnie KO - wzieła ona przedostatni mandat 1k głosów przed PIS ale daleko przed Lewica i TD. 10k zaświadczeń i to było 1.4x tego co w 2019.",
"Bilans partii demokratycznych +1 w stosunku do przewidywań. Jednak wbrew rekomendacji Lewicy nie wystarczyło 6k głosów do zabrania ostaniego mandatu PiS. Prawie 8k zaświadczeń to tylko 1.7 w stosunku do 2019.",
"W stosunku do przewidywań TD +1 a Lewica -1, ostatni mandat PIS przed Trzecią, może być duży wpływ turystyki bo 15k zaświadczeń, i x4 w stosunku do 2019. Prawdopodobnie to przyjezdne glosy na Hołownię. Jednak ten mandat był bezpieczny dla Trzeciej, bo żadne ugrupowanie nie było blisko za TD poza PiS ktore i tak dostało mandat.",
"KO odebrało Konfederacji ostatni mandat. Wyprzedziło ją o 4k. Było 13k zaświadczeń tylko 1.6x 2019. Prawdopodobnie to studenci i osoby przyjezdne.",
"Wynik zgodny z przewidywaniami, rzeczywiście KO dostało ostani mandat, ale daleko przed PiS (16k+). Choć może dzięki turystyce bo było 14k zaświdaczeń - x2 w stosunku do 2019.",
"Zła rekomendacja, KO nie walczyła o ostatni mandat - robiły to Lewica i TD. Lewicy zabrakło 300 głosów by odebrać mandat Konfederacji.",
"KO odebrało mandat Lewicy w stosunku do przewidywań. Wyprzedziło lewicę o 600 głosów. Nie było zagrożenia w relacji KO / PiS. Demokraci dostali zgodnie z szacunkami.",
"KO odebrał Konfederacji mandat w stosunku do przewidywań, rekomendacja prawidłowa bo KO dostała ostatni mandat. Wpływ turystyki prawdopodobnie był znikomy, bo tylko 4k zaświadczeń - x2 do 2019.",
"Rozklad zgodny z przewidywaniami, ale wskazana strategia powinna być tylko na Lewicę, bo zabrakło jej 4k aby przegonić Konfederacje w biegu o ostani mandat. Tu niemal nie było turystyki - tylko 3.5k, ale to i tak wzrost do 2019 x3.",
"Rozkład zgodny z przewidywaniami, choć rekomendacja na TD mogła dać 1 mandat więcej dla opozycji demokratycznej. TD zabrakło tylko 1k mandatów by odebrać mandat Konfederacji. 7k zaświadczeń to 2.5x w stosunku do 2019.",
"Zgodnie z przewidywaniami Trzecia dostała ostatni mandat, więc rekomendacja prawidłowa, choć było on bezpieczny, bo Konfederacja daleko z tyłu. Rozkład mandatów zgodny z przewidywaniami. 6k zaświadczeń to x4 w stosunku do 2019.",
"KO odebrało PiS mandat w stosunku do przewidywań, przegoniła PiS o ok 100 głosów, czyli rekomendacja była prawidłowa. 12k zaświadczeń to 2.5x w stosunku do 2019.",
"PiS odebrał Lewicy mandat, wbrew przewidywanemu scenariuszowi. Rekomendowane wsparcie dla Lewicy było wymagane, ale nie wystarczyło, zabrakło jej 2k głosów by odebrać mandat PiS. 5k zaświadczeń to x3 w stosunku do 2019, ale pewnie większość na KO, ktore z zapasem zdobyło 3 mandaty.",
"Rekomendacja prawidłowa, ale odzew nie wystarczający. Wyglada na to że Turystyka mogła pomoc, 8k zaświadczeń w stosunku to x2 w stosunku do 2019. KO wzięło przedostani mandat +3k przed PiS, któtre zabrało mandat TD - zabrakło jej zaledwie 300 głosów do mandatu. Przewidziana liczba mandatów dla demokratów.",
"KO wzięło ostatni mandat, przeganiając o 1000 głosów Konfederację. Osób z zaświadczeniem 5k, czyli prawie 3x tego co w 2019. Turystyka mogłą mieć więc wpływ.",
"KO rzeczywiście walczyło o mandat ale zostało wyminięte przez TD o 5000 głosów (wynik lepszy niż przewidywany). 5k zaświadczeń, czyli 3.5x tego co w 2019. Konfederacja 1000 głosów za KO. Rekomendacja bez znaczenia ale nie błędna. KO wzieło i tak przewidywaną liczbę mandatówm ale dawaliśmy szanse na +1 na KO, podczas gdy realnie +1 wzieło TD a -1 Konfederacja.",
"KO wzięło ostatni mandat, Trzecia Droga przedostatni o 900 głosów wcześniej. Dobra rekomendacja - rzeczywiście Trzecia Droga potrzebowała wsparcia. Wszystkich głosów z zaświadczeniem było 5k, a więc ponad 3x więcej niż w 2019. Przewidywaliśmy na podstawie lepszych sondaży Lewicy +1 dla Lewicy i zagrożenie -1 dla KO.",
"Ostatni mandat wzięła Trzecia Droga, rekomendacja była zła. Przewidywaliśmy +1 dla KO i -1 dla Trzeciej.",
"Ostatni mandat zdobyła KO, 14k osób zagłosowało z zaświadczeniem - jest to 48% kwoty mandatu i prawie x2 w stosunku do 2019. Jednak KO ten mandat odebrała Lewicy. Nie przewidzieliśmy tak niskiego poparcia dla Lewicy, a więc ryzyka straty mandatu.",
"Ostatni mandat zdobyła KO, 13k osób z zaświadczeniem, odebrała ona ostatni mandat TD i Konfederacji, którym zabrakło po 5k do ostatniego mandatu. Prawdopodobnie część osób z zaświadczeniem to osoby mieszkające na stałe w Niemczech.",
];

export const zaswiadczenia = [
{ zaswiadczenia2019: 5597, zaswiadczenia2023: 11196},
{ zaswiadczenia2019: 4854, zaswiadczenia2023: 7157},
{ zaswiadczenia2019: 8921, zaswiadczenia2023: 14233},
{ zaswiadczenia2019: 3538, zaswiadczenia2023: 8692},
{ zaswiadczenia2019: 5302, zaswiadczenia2023: 11762},
{ zaswiadczenia2019: 4939, zaswiadczenia2023: 9488},
{ zaswiadczenia2019: 3251, zaswiadczenia2023: 7632},
{ zaswiadczenia2019: 3003, zaswiadczenia2023: 10170},
{ zaswiadczenia2019: 2941, zaswiadczenia2023: 7875},
{ zaswiadczenia2019: 1725, zaswiadczenia2023: 5312},
{ zaswiadczenia2019: 2206, zaswiadczenia2023: 6920},
{ zaswiadczenia2019: 1780, zaswiadczenia2023: 3971},
{ zaswiadczenia2019: 9912, zaswiadczenia2023: 14219},
{ zaswiadczenia2019: 10724, zaswiadczenia2023: 13737},
{ zaswiadczenia2019: 1909, zaswiadczenia2023: 4824},
{ zaswiadczenia2019: 1885, zaswiadczenia2023: 7596},
{ zaswiadczenia2019: 1728, zaswiadczenia2023: 7378},
{ zaswiadczenia2019: 2582, zaswiadczenia2023: 10706},
{ zaswiadczenia2019: 38061, zaswiadczenia2023: 63438},
{ zaswiadczenia2019: 5330, zaswiadczenia2023: 19912},
{ zaswiadczenia2019: 2414, zaswiadczenia2023: 6444},
{ zaswiadczenia2019: 6939, zaswiadczenia2023: 9928},
{ zaswiadczenia2019: 4375, zaswiadczenia2023: 7669},
{ zaswiadczenia2019: 3680, zaswiadczenia2023: 15150},
{ zaswiadczenia2019: 8501, zaswiadczenia2023: 13692},
{ zaswiadczenia2019: 7362, zaswiadczenia2023: 14709},
{ zaswiadczenia2019: 4575, zaswiadczenia2023: 8734},
{ zaswiadczenia2019: 1864, zaswiadczenia2023: 3902},
{ zaswiadczenia2019: 1759, zaswiadczenia2023: 4218},
{ zaswiadczenia2019: 1107, zaswiadczenia2023: 3661},
{ zaswiadczenia2019: 2886, zaswiadczenia2023: 7392},
{ zaswiadczenia2019: 1516, zaswiadczenia2023: 6292},
{ zaswiadczenia2019: 4587, zaswiadczenia2023: 12070},
{ zaswiadczenia2019: 1772, zaswiadczenia2023: 5273},
{ zaswiadczenia2019: 3804, zaswiadczenia2023: 7975},
{ zaswiadczenia2019: 1897, zaswiadczenia2023: 5234},
{ zaswiadczenia2019: 1504, zaswiadczenia2023: 5201},
{ zaswiadczenia2019: 1825, zaswiadczenia2023: 5834},
{ zaswiadczenia2019: 6156, zaswiadczenia2023: 10015},
{ zaswiadczenia2019: 7719, zaswiadczenia2023: 14999},
{ zaswiadczenia2019: 6380, zaswiadczenia2023: 13499},
]