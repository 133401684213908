import { PodzialMandatowWOkregu } from "./ordynacja";
import { Wynik, Okreg, Sondaze } from "./typy";

export const PARTIE = ["LEWICA", "KO", "TD", "KONF", "PIS"];

export const KOLOR_PARTII = {
  PIS: "rgb(38, 55, 120)",
  LEWICA: "rgb(171,20,91)",
  KONF: "rgb(0, 0, 0)",
  TD: "rgb(220, 195, 16)",
  KO: "rgb(0,68,149)",
  BIAŁY: "rgb(255,255,255)",
};

export const sondaze: Sondaze = {
  PKW_20231017: {
    PIS: 35.38,
    KO: 30.7,
    TD: 14.40,
    LEWICA: 8.61,
    KONF: 7.16,
  },
  exitpoll_20231015: {
    PIS: 36.8,
    KO: 31.6,
    TD: 13.0,
    LEWICA: 8.6,
    KONF: 6.2,
  },
  Opinia24_20231013: {
    PIS: 32.0,
    KO: 27.5,
    TD: 11.2,
    LEWICA: 7.9,
    KONF: 8.8,
  },
  IBSP_20231013: {
    PIS: 37.36,
    KO: 27.77,
    TD: 13.02,
    LEWICA: 10.33,
    KONF: 8.29,
  },
  IBRiS_20231012: {
    PIS: 33.9,
    KO: 27.0,
    TD: 11.0,
    LEWICA: 9.7,
    KONF: 8.2,
  },
  Kantar_20231012: {
    PIS: 30.0,
    KO: 26.0,
    TD: 12.0,
    LEWICA: 11.0,
    KONF: 8.0,
  },
  IBRiS_20231011: {
    PIS: 34.9,
    KO: 28.1,
    TD: 10.3,
    LEWICA: 10.0,
    KONF: 8.3,
  },
  IBRiS_20231010: {
    PIS: 33.5,
    KO: 28.0,
    TD: 10.9,
    LEWICA: 10.1,
    KONF: 9.2,
  },
  // ewybory_20231010: {
  //   PIS: 33.45,
  //   KO: 30.85,
  //   TD: 11.03,
  //   LEWICA: 12.88,
  //   KONF: 8.5,
  // },
  // IPSOS_20231010: {
  //   PIS: 36.0,
  //   KO: 28.0,
  //   TD: 8.0,
  //   LEWICA: 8.0,
  //   KONF: 9.0,
  // },
  // Pollster_CAWI_20231007: {
  //   PIS: 34.3,
  //   KO: 30.0,
  //   TD: 11.8,
  //   LEWICA: 8.2,
  //   KONF: 8.2,
  // },
  // IBRiS_20231007: {
  //   PIS: 34.6,
  //   KO: 27.9,
  //   TD: 7.6,
  //   LEWICA: 11.4,
  //   KONF: 7.7,
  // },
  // Estymator_20231006: {
  //   PIS: 36.9,
  //   KO: 30.5,
  //   TD: 9.4,
  //   LEWICA: 9.3,
  //   KONF: 9.3,
  // },
  // Opinia24_20231004_plus_niezdecydowani: {
  //   PIS: 32.9,
  //   KO: 31.1,
  //   TD: 13.2,
  //   LEWICA: 11.3,
  //   KONF: 6.5,
  // },
  // Opinia24_20231004: {
  //   PIS: 30.0,
  //   KO: 25.0,
  //   TD: 6.0,
  //   LEWICA: 9.0,
  //   KONF: 5.0,
  // },
  // IBRiS_20231004: {
  //   PIS: 34.0,
  //   KO: 28.9,
  //   TD: 10.6,
  //   LEWICA: 10.0,
  //   KONF: 9.7,
  // },
  // Kantar_20231004: {
  //   PIS: 34.0,
  //   KO: 30.0,
  //   TD: 9.0,
  //   LEWICA: 10.0,
  //   KONF: 8.0,
  // },
  // UnitedSurveys_20231002: {
  //   PIS: 32.0,
  //   KO: 28.2,
  //   TD: 11.0,
  //   LEWICA: 10.2,
  //   KONF: 10.0,
  // },
  // UnitedSurveys_20231001: {
  //   PIS: 32.5,
  //   KO: 26.4,
  //   TD: 12.1,
  //   LEWICA: 8.1,
  //   KONF: 9.0,
  // },
  // Estymator_20230930: {
  //   PIS: 37.4,
  //   KO: 30.6,
  //   TD: 9.6,
  //   LEWICA: 8.7,
  //   KONF: 9.5,
  // },
  // IBRiS_20230927: {
  //   PIS: 35.1,
  //   KO: 27.0,
  //   TD: 10.4,
  //   LEWICA: 10.1,
  //   KONF: 9.5,
  // },
  // IPSOS_20230925: {
  //   PIS: 36.0,
  //   KO: 29.0,
  //   TD: 8.0,
  //   LEWICA: 10,
  //   KONF: 7.0,
  // },
  // UnitedSurveys_20230924: {
  //   PIS: 33.8,
  //   KO: 28.1,
  //   TD: 9.0,
  //   LEWICA: 8.7,
  //   KONF: 8.8,
  // },
  // Estymator_20230923: {
  //   PIS: 37.3,
  //   KO: 29.8,
  //   TD: 9.9,
  //   LEWICA: 8.5,
  //   KONF: 10.6,
  // },
  // Pollster_20230922: {
  //   PIS: 36.8,
  //   KO: 30.4,
  //   TD: 9.8,
  //   LEWICA: 9.7,
  //   KONF: 8.7,
  // },
  // IBRiS_20230920: {
  //   PIS: 35.1,
  //   KO: 26.1,
  //   TD: 9.2,
  //   LEWICA: 9.1,
  //   KONF: 9.9,
  // },
  // UnitedSurveys_20230919: {
  //   PIS: 31.6,
  //   KO: 27.0,
  //   TD: 10.2,
  //   LEWICA: 9.9,
  //   KONF: 9.3,
  // },
  // ResearchPartner_20230918: {
  //   PIS: 35.4,
  //   KO: 26.1,
  //   TD: 10.1,
  //   LEWICA: 8.0,
  //   KONF: 9.5,
  // },
  // UnitedSurveys_20230917: {
  //   PIS: 32.8,
  //   KO: 26.3,
  //   TD: 10.6,
  //   LEWICA: 10.2,
  //   KONF: 9.5,
  // },
  // IBRiS_20230917: {
  //   PIS: 32.6,
  //   KO: 26.6,
  //   TD: 10.6,
  //   LEWICA: 9.9,
  //   KONF: 9.5,
  // },
};

export const sondazeDoSredniej = {
  // PKW_20231017: sondaze.PKW_20231017,
  // exitpoll_20231015: sondaze.exitpoll_20231015,
  // Opinia24_20231013: sondaze.Opinia24_20231013,
  // IBSP_20231013: sondaze.IBSP_20231013,
  // IBRiS_20231012: sondaze.IBRiS_20231012,
  Kantar_20231012: sondaze.Kantar_20231012,
  IBRiS_20231011: sondaze.IBRiS_20231011,
  IBRiS_20231010: sondaze.IBRiS_20231010,
  // ewybory_20231010: sondaze.ewybory_20231010,
  // IPSOS_20231010: sondaze.IPSOS_20231010,
  // IBRiS_20231007: sondaze.IBRiS_20231007,
  // Estymator_20231006: sondaze.Estymator_20231006,
  // Opinia24_20231004: sondaze.Opinia24_20231004,
  // IBRiS_20231004: sondaze.IBRiS_20231004,
  // Kantar_20231004: sondaze.Kantar_20231004,
};

// Do wyświetlenia
export const srednia: Wynik = {};
// Do obliczeń: znormalizowane do 100%
export const sredniaSondazy: Wynik = {};
Object.entries(sondazeDoSredniej).forEach(([sondaz, wynik]) => {
  Object.entries(wynik).forEach(([partia, procent]) =>
    sredniaSondazy[partia]
      ? (sredniaSondazy[partia] += procent)
      : (sredniaSondazy[partia] = procent)
  );
});


const sumaProcentow =
  Object.values(sredniaSondazy).reduce((acc, curr) => (acc += curr), 0) /
  Object.entries(sondazeDoSredniej).length;


// TODO @tomek warto zweryfikować, zwłaszcza zaokrąglenia

Object.entries(sredniaSondazy).forEach(([partia, procent]) => {
  srednia[partia] = (sredniaSondazy[partia] / Object.entries(sondazeDoSredniej).length);
  sredniaSondazy[partia] =
    (sredniaSondazy[partia] / Object.entries(sondazeDoSredniej).length) *
    (100 / sumaProcentow);
});

// TODO: @tomek potrzeba by zweryfikować czy dane są poprawne

// Źródło: https://sejmsenat2019.pkw.gov.pl/sejmsenat2019/pl/wyniki/sejm/pl
export const wybory2019: Wynik = {
  PIS: 43.59,
  KO: 27.4,
  LEWICA: 12.56,
  PSL: 8.55,
  KONF: 6.81,
};

// Źródło: https://sejmsenat2019.pkw.gov.pl/sejmsenat2019/data/csv/wyniki_gl_na_listy_po_okregach_sejm_csv.zip
export const okregi: Okreg[] = [
  {
    nr: 1,
    miasto: "Legnica",
    mandaty: 12,
    mandatyPKW: 11,
    glosy2019: 432436,
    wynik2019: {
      KO: 25.02,
      KONF: 5.85,
      PSL: 7.17,
      PIS: 42.4,
      LEWICA: 16.43,
    },
  },
  {
    nr: 2,
    miasto: "Wałbrzych",
    mandaty: 8,
    mandatyPKW: 7,
    glosy2019: 283002,
    wynik2019: {
      KO: 32.09,
      KONF: 5.42,
      PSL: 7.25,
      PIS: 40.54,
      LEWICA: 12.35,
    },
  },
  {
    nr: 3,
    miasto: "Wrocław",
    mandaty: 14,
    mandatyPKW: 15,
    glosy2019: 654455,
    wynik2019: {
      KO: 32.8,
      KONF: 7.45,
      PSL: 6.46,
      PIS: 34.67,
      LEWICA: 15.41,
    },
  },
  {
    nr: 4,
    miasto: "Bydgoszcz",
    mandaty: 12,
    mandatyPKW: 12,
    glosy2019: 459982,
    wynik2019: {
      KO: 31.05,
      KONF: 7.05,
      PSL: 9.02,
      PIS: 36.43,
      LEWICA: 15.17,
    },
  },
  {
    nr: 5,
    miasto: "Toruń",
    mandaty: 13,
    mandatyPKW: 12,
    glosy2019: 452330,
    wynik2019: {
      KO: 26.42,
      KONF: 6.33,
      PSL: 10.88,
      PIS: 40.38,
      LEWICA: 14.83,
    },
  },
  {
    nr: 6,
    miasto: "Lublin",
    mandaty: 15,
    mandatyPKW: 14,
    glosy2019: 565597,
    wynik2019: {
      KO: 19.3,
      KONF: 7.07,
      PSL: 9.1,
      PIS: 55.39,
      LEWICA: 7.81,
    },
  },
  {
    nr: 7,
    miasto: "Chełm",
    mandaty: 12,
    mandatyPKW: 11,
    glosy2019: 401318,
    wynik2019: {
      KO: 14.8,
      KONF: 5.84,
      PSL: 11.86,
      PIS: 59.5,
      LEWICA: 6.83,
    },
  },
  {
    nr: 8,
    miasto: "Zielona Góra",
    mandaty: 12,
    mandatyPKW: 12,
    glosy2019: 437917,
    wynik2019: {
      KO: 31.27,
      KONF: 7.19,
      PSL: 11.63,
      PIS: 34.3,
      LEWICA: 15.61,
    },
  },
  {
    nr: 9,
    miasto: "Łódź",
    mandaty: 10,
    mandatyPKW: 9,
    glosy2019: 415540,
    wynik2019: {
      KO: 35.82,
      KONF: 6.65,
      PSL: 4.53,
      PIS: 32.9,
      LEWICA: 20.1,
    },
  },
  {
    nr: 10,
    miasto: "Piotrków Trybunalski",
    mandaty: 9,
    mandatyPKW: 9,
    glosy2019: 346326,
    wynik2019: {
      KO: 15.64,
      KONF: 6.76,
      PSL: 10.44,
      PIS: 56.21,
      LEWICA: 10.95,
    },
  },
  {
    nr: 11,
    miasto: "Sieradz",
    mandaty: 12,
    mandatyPKW: 12,
    glosy2019: 460239,
    wynik2019: {
      KO: 20.48,
      KONF: 5.88,
      PSL: 10.29,
      PIS: 49.81,
      LEWICA: 11.98,
    },
  },
  {
    nr: 12,
    miasto: "Chrzanów",
    mandaty: 8,
    mandatyPKW: 8,
    glosy2019: 316214,
    wynik2019: {
      KO: 23.04,
      KONF: 7.06,
      PSL: 7.9,
      PIS: 53.48,
      LEWICA: 8.51,
    },
  },
  {
    nr: 13,
    miasto: "Kraków",
    mandaty: 14,
    mandatyPKW: 15,
    glosy2019: 649287,
    wynik2019: {
      KO: 30.48,
      KONF: 7.99,
      PSL: 7.27,
      PIS: 39.56,
      LEWICA: 13.01,
    },
  },
  {
    nr: 14,
    miasto: "Nowy Sącz",
    mandaty: 10,
    mandatyPKW: 10,
    glosy2019: 370199,
    wynik2019: {
      KO: 13.83,
      KONF: 6.95,
      PSL: 7.35,
      PIS: 65.8,
      LEWICA: 6.07,
    },
  },
  {
    nr: 15,
    miasto: "Tarnów",
    mandaty: 9,
    mandatyPKW: 9,
    glosy2019: 347088,
    wynik2019: {
      KO: 14.0,
      KONF: 7.11,
      PSL: 13.35,
      PIS: 59.59,
      LEWICA: 5.94,
    },
  },
  {
    nr: 16,
    miasto: "Płock",
    mandaty: 10,
    mandatyPKW: 10,
    glosy2019: 370561,
    wynik2019: {
      KO: 16.85,
      KONF: 5.24,
      PSL: 15.17,
      PIS: 52.45,
      LEWICA: 8.76,
    },
  },
  {
    nr: 17,
    miasto: "Radom",
    mandaty: 9,
    mandatyPKW: 9,
    glosy2019: 335009,
    wynik2019: {
      KO: 17.15,
      KONF: 5.89,
      PSL: 10.2,
      PIS: 57.82,
      LEWICA: 7.43,
    },
  },
  {
    nr: 18,
    miasto: "Siedlce",
    mandaty: 12,
    mandatyPKW: 12,
    glosy2019: 452906,
    wynik2019: {
      KO: 13.94,
      KONF: 6.49,
      PSL: 11.94,
      PIS: 59.76,
      LEWICA: 6.45,
    },
  },
  {
    nr: 19,
    miasto: "Warszawa I",
    mandaty: 20,
    mandatyPKW: 21,
    glosy2019: 1381917,
    wynik2019: {
      KO: 42.05,
      KONF: 7.51,
      PSL: 4.75,
      PIS: 27.49,
      LEWICA: 18.19,
    },
  },
  {
    nr: 20,
    miasto: "Warszawa II",
    mandaty: 12,
    mandatyPKW: 14,
    glosy2019: 598727,
    wynik2019: {
      KO: 28.61,
      KONF: 6.63,
      PSL: 8.6,
      PIS: 40.89,
      LEWICA: 13.09,
    },
  },
  {
    nr: 21,
    miasto: "Opole",
    mandaty: 12,
    mandatyPKW: 12,
    glosy2019: 406439,
    wynik2019: {
      KO: 26.71,
      KONF: 5.7,
      PSL: 10.31,
      PIS: 37.64,
      LEWICA: 11.74,
    },
  },
  {
    nr: 22,
    miasto: "Krosno",
    mandaty: 11,
    mandatyPKW: 11,
    glosy2019: 390581,
    wynik2019: {
      KO: 15.94,
      KONF: 6.81,
      PSL: 7.85,
      PIS: 63.36,
      LEWICA: 6.04,
    },
  },
  {
    nr: 23,
    miasto: "Rzeszów",
    mandaty: 15,
    mandatyPKW: 16,
    glosy2019: 588786,
    wynik2019: {
      KO: 14.39,
      KONF: 8.25,
      PSL: 7.79,
      PIS: 62.38,
      LEWICA: 6.59,
    },
  },
  {
    nr: 24,
    miasto: "Białystok",
    mandaty: 14,
    mandatyPKW: 14,
    glosy2019: 520578,
    wynik2019: {
      KO: 21.04,
      KONF: 6.96,
      PSL: 9.33,
      PIS: 52.04,
      LEWICA: 9.09,
    },
  },
  {
    nr: 25,
    miasto: "Gdańsk",
    mandaty: 12,
    mandatyPKW: 13,
    glosy2019: 528829,
    wynik2019: {
      KO: 41.31,
      KONF: 7.21,
      PSL: 5.9,
      PIS: 32.1,
      LEWICA: 13.47,
    },
  },
  {
    nr: 26,
    miasto: "Gdynia",
    mandaty: 14,
    mandatyPKW: 15,
    glosy2019: 580722,
    wynik2019: {
      KO: 35.85,
      KONF: 7.3,
      PSL: 7.94,
      PIS: 36.43,
      LEWICA: 12.47,
    },
  },
  {
    nr: 27,
    miasto: "Bielsko-Biała",
    mandaty: 9,
    mandatyPKW: 9,
    glosy2019: 389256,
    wynik2019: {
      KO: 27.2,
      KONF: 7.42,
      PSL: 7.13,
      PIS: 46.76,
      LEWICA: 11.48,
    },
  },
  {
    nr: 28,
    miasto: "Częstochowa",
    mandaty: 7,
    mandatyPKW: 7,
    glosy2019: 284517,
    wynik2019: {
      KO: 22.63,
      KONF: 6.07,
      PSL: 8.68,
      PIS: 44.28,
      LEWICA: 15.59,
    },
  },
  {
    nr: 29,
    miasto: "Gliwice",
    mandaty: 9,
    mandatyPKW: 9,
    glosy2019: 340647,
    wynik2019: {
      KO: 32.61,
      KONF: 7.67,
      PSL: 5.99,
      PIS: 37.75,
      LEWICA: 13.38,
    },
  },
  {
    nr: 30,
    miasto: "Rybnik",
    mandaty: 9,
    mandatyPKW: 9,
    glosy2019: 333836,
    wynik2019: {
      KO: 27.71,
      KONF: 7.17,
      PSL: 5.64,
      PIS: 48.28,
      LEWICA: 9.68,
    },
  },
  {
    nr: 31,
    miasto: "Katowice",
    mandaty: 12,
    mandatyPKW: 11,
    glosy2019: 469633,
    wynik2019: {
      KO: 37.2,
      KONF: 7.33,
      PSL: 4.37,
      PIS: 39.19,
      LEWICA: 11.92,
    },
  },
  {
    nr: 32,
    miasto: "Sosnowiec",
    mandaty: 9,
    mandatyPKW: 8,
    glosy2019: 335431,
    wynik2019: {
      KO: 29.66,
      KONF: 6.45,
      PSL: 4.85,
      PIS: 37.13,
      LEWICA: 21.9,
    },
  },
  {
    nr: 33,
    miasto: "Kielce",
    mandaty: 16,
    mandatyPKW: 15,
    glosy2019: 569891,
    wynik2019: {
      KO: 16.65,
      KONF: 5.95,
      PSL: 9.88,
      PIS: 55.18,
      LEWICA: 9.95,
    },
  },
  {
    nr: 34,
    miasto: "Elbląg",
    mandaty: 8,
    mandatyPKW: 7,
    glosy2019: 250819,
    wynik2019: {
      KO: 28.43,
      KONF: 5.66,
      PSL: 10.89,
      PIS: 40.86,
      LEWICA: 11.64,
    },
  },
  {
    nr: 35,
    miasto: "Olsztyn",
    mandaty: 10,
    mandatyPKW: 10,
    glosy2019: 331684,
    wynik2019: {
      KO: 26.46,
      KONF: 6.97,
      PSL: 13.19,
      PIS: 38.82,
      LEWICA: 13.84,
    },
  },
  {
    nr: 36,
    miasto: "Kalisz",
    mandaty: 12,
    mandatyPKW: 12,
    glosy2019: 459152,
    wynik2019: {
      KO: 24.72,
      KONF: 6.57,
      PSL: 12.8,
      PIS: 42.48,
      LEWICA: 13.43,
    },
  },
  {
    nr: 37,
    miasto: "Konin",
    mandaty: 9,
    mandatyPKW: 10,
    glosy2019: 353041,
    wynik2019: {
      KO: 20.48,
      KONF: 6.74,
      PSL: 9.81,
      PIS: 47.29,
      LEWICA: 15.04,
    },
  },
  {
    nr: 38,
    miasto: "Piła",
    mandaty: 9,
    mandatyPKW: 10,
    glosy2019: 349051,
    wynik2019: {
      KO: 30.6,
      KONF: 6.62,
      PSL: 13.86,
      PIS: 35.64,
      LEWICA: 13.28,
    },
  },
  {
    nr: 39,
    miasto: "Poznań",
    mandaty: 10,
    mandatyPKW: 11,
    glosy2019: 514527,
    wynik2019: {
      KO: 45.38,
      KONF: 6.61,
      PSL: 6.2,
      PIS: 25.33,
      LEWICA: 16.49,
    },
  },
  {
    nr: 40,
    miasto: "Koszalin",
    mandaty: 8,
    mandatyPKW: 7,
    glosy2019: 271711,
    wynik2019: {
      KO: 32.31,
      KONF: 5.98,
      PSL: 9.43,
      PIS: 36.83,
      LEWICA: 15.44,
    },
  },
  {
    nr: 41,
    miasto: "Szczecin",
    mandaty: 12,
    mandatyPKW: 12,
    glosy2019: 470529,
    wynik2019: {
      KO: 35.71,
      KONF: 6.53,
      PSL: 7.4,
      PIS: 35.11,
      LEWICA: 15.25,
    },
  },
];

// [KO,KONF,PSL,PIS,SLD],
export const glosy2019 = [
[108191,25319,31006,183364,71061],
[90812,15346,20528,114728,34957],
[214629,48775,42269,226915,100843],
[142844,32406,41497,167550,69763],
[119526,28625,49225,182648,67076],
[109185,40012,51474,313284,44152],
[59401,23439,47604,238802,27404],
[136955,31490,50943,150188,68341],
[148830,27627,18828,136731,83524],
[54160,23427,36151,194658,37930],
[94268,27054,47373,229245,55116],
[72869,22334,24996,169106,26909],
[197930,51855,47219,256847,84457],
[51183,25747,27203,243583,22483],
[48597,24695,46333,206845,20618],
[62429,19405,56227,194371,32448],
[57449,19724,34185,193709,24884],
[63124,29390,54085,270641,29235],
[581077,103843,65683,379880,251434],
[171286,39675,51484,244823,78348],
[108570,23176,41901,152999,47699],
[62246,26615,30655,247488,23577],
[84703,48600,45868,367268,38817],
[109527,36207,48566,270888,47342],
[218484,38153,31203,169753,71236],
[208208,42364,46132,211582,72436],
[105876,28900,27752,182027,44701],
[64374,17278,24704,125990,44354],
[111078,26114,20408,128579,45583],
[92493,23939,18816,161160,32300],
[174683,34416,20512,184030,55992],
[99499,21650,16263,124553,73466],
[94880,33895,56289,314455,56699],
[71320,14187,27319,102478,29196],
[87780,23134,43758,128760,45912],
[113489,30177,58759,195053,61674],
[72295,23810,34620,166965,53090],
[106810,23123,48371,124392,46355],
[233474,34024,31875,130319,84835],
[87799,16259,25632,100078,41943],
[168022,30744,34807,165200,71756]]
